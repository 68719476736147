@import '~ngx-toastr/toastr';
@import '~codemirror/lib/codemirror';
@import '~codemirror/theme/material';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-smart-modal/ngx-smart-modal";

@font-face {
  font-family: IRANSans;
  src: 
  url(/assets/fonts/IRANSans.eot?#iefix) format("embedded-opentype"),
   url(/assets/fonts/IRANSans.woff) format("woff"),
   url(/assets/fonts/IRANSans.ttf) format("ttf");
  font-weight: normal;
  font-style: normal; 
}

html {
  height: 100%;
}

body {
  font-family: 'IRANSans',Roboto, Arial, sans-serif;
  margin: 0;
  height: 100%;
  background-color: rgb(235, 235, 235);
}

.animated {
  -webkit-animation-duration : 1s;
  animation-duration         : 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode        : both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name        : fadeIn;
}

.spacer {
  flex: 1 1 auto !important;
}

.v-center{
  align-self: center;
}
.back-button{
  margin-left: 1em;
  align-self: center;
}

.widget-header{
  .card-right-side{
    margin-top: 0.5em;
    padding-bottom: 1em;
  }
  .card-left-side{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.select{
  height: fit-content;
}
.min-width{
  min-width: 300px;
}

.idkSpacing{
  padding-right: 0;
  padding-left: 0;
}
.registerLayout{
  color: inherit;
  height: inherit;
  font-size: inherit;
  background: inherit;
  overflow-x: inherit;
  overflow-y: inherit;
  letter-spacing: inherit;
}

.relative{
  position: relative;
}
.deleteAttachment{
  top: -15px;
  right: -10px;
  position: absolute;
  z-index: 1;
  background: transparent;
  color: red;
  width: 25px;
  cursor: pointer;
  text-align: center;
}

.mb-6{
  margin-bottom: 6rem;
}

.dir-right{
  direction: rtl;
}